.schedule-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .schedule-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .schedule-card {
    background: linear-gradient(45deg, #59c2e5, #007bff);
    color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .time-slot {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .no-call-data {
    text-align: center;
    margin-top: 10px;
  }
  
  .call-entry {
    margin-bottom: 10px;
  }
  
  .call-name {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
  }
  
  .phone-icon {
    margin-right: 8px;
    font-size: 20px;
  }
  
  .call-time {
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
  }
  
  .horizontal-line {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  /* Hover effect */
  .schedule-card:hover {
    transform: translateY(-5px);
    transition: transform 0.3s ease;
  }
  .call-link {
    text-decoration: none;
    color: inherit;
  }













  